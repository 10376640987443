.filepond--panel-root {
  border: 3px solid #c5d7ee;
  background-color: #e1f1fb;
}

.filepond--drop-label {
  height: 200px !important;
  background-image: url('./uploadImageCover.svg');
  background-repeat: no-repeat;
  background-position: center 30px;
  cursor: pointer;
  padding-top: 70px;
}
