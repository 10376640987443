* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 15em;
}

.logoContainer {
  display: flex;
  justify-content: center;
  padding-top: 25vh;
}

.logo {
  height: 50px;
}

.circularProgress {
  color: #0286a4 !important;
}

.Mui-disabled-custom {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
